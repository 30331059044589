import React, { useState } from "react";
import styles from "./PlaceOrder.module.css";
import cx from "classnames";
import { IoArrowBack } from "react-icons/io5";
import { optionsContent } from "./OptionsContent";
import GradientButton from "../../../reusables/GradientButton/GradientButton";
import BulkOrderModal from "../../../reusables/BulkOrderModal/BulkOrderModal";
import SearchBar from "../../../reusables/SearchBar/SearchBar";

function PlaceOrder(props) {
  const [checked, setChecked] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [showBulkOrder, setShowBulkOrder] = useState(false);
  console.log(props.location);

  const handleContinue = () => {
    switch (checked) {
      case 0:
        return;
      case 1:
        setShowSearch(true);
        return;
      case 2:
        setShowBulkOrder(true);
        return;
      case 3:
        return;

      default:
        return;
    }
  };

  return (
    <div className={styles.container}>
      <BulkOrderModal
        isOpen={showBulkOrder}
        onClose={() => setShowBulkOrder(false)}
      />
      <div style={{ flexDirection: "column" }}>
        <div className={styles.body}>
          <div className={styles.stepBox}>
            <p
              className={styles.heading1}
            >
              Uploaded prescription
            </p>
            <div
            className={styles.box1inner}
            >
              <img
                src={props.location.state.image}
                alt="Uploaded prescription"
                className={styles.pres}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.secondBox}>
        {!showSearch ? (
          <>
             <p className={styles.placeOrderText}>
              Place your order
            </p>
            {optionsContent.map((item) => (
              <div
                className={styles.checkBoxDiv}
                onClick={() => setChecked(item.id)}
              >
                <div className={styles.checkBox}>
                  <div
                    className={
                      checked === item.id ? styles.activeRound : styles.round
                    }
                  >
                    <div className={checked === item.id && styles.active}></div>
                  </div>
                  <div>
                    <p
                     className={styles.checkText}
                    >
                      {item.text}
                    </p>
                  </div>
                </div>
                {checked === item.id && item.description && (
                  <p
                    style={{
                      color: "#6f6f6f",
                      marginLeft: "15px",
                      textAlign: "left",
                      border: "1px solid #dddddd",
                      padding: 5,
                      marginRight: 10,
                      borderRadius: 5,
                      fontSize: "13px",
                    }}
                  >
                    {item.description}
                  </p>
                )}
              </div>
            ))}
            <div
             className={styles.bottomButton}
            >
              <GradientButton
                title="Continue"
                style={
                  checked === null
                    ? {
                        backgroundColor: "#d8d8d8",
                        backgroundImage: "none",
                        width: 150,
                      }
                    : { width: 150 }
                }
                onClick={handleContinue}
              />
            </div>
          </>
        ) : (
          <div style={{ margin: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{ cursor: "pointer", padding: 10 }}
                onClick={() => setShowSearch(false)}
              >
                <IoArrowBack size={25} color="#1bc2ad" />
              </div>
              <p style={{ color: "#6f6f6f", fontSize: 18, margin: 10 }}>
                Search and add medicines manually to your cart
              </p>
            </div>
            <div style={{ margin: 10 }}>
              <SearchBar flex={4} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlaceOrder;
