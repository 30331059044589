import React from "react";
import { Form } from "react-bootstrap";
import OtpInput from "react-otp-input";
import GradientButton from "../GradientButton/GradientButton";

function OtpInputUtil({ otp, setOtp }) {
  return (
    <Form.Group>
      <Form.Label>Enter OTP</Form.Label>
      <OtpInput
        value={otp}
        onChange={(value) => setOtp(value)}
        inputStyle={{
          marginRight: 5,
          marginLeft: 5,
          width: 35,
          height: 35,
          borderWidth: 1,
          borderColor: "#1bc2ad",
        }}
        numInputs={5}
        isInputNum={true}
        separator={<span> </span>}
        focusStyle={{ outline: "none" }}
      />
      <GradientButton
        variant="Outline"
        style={{
          borderWidth: 0,
          fontSize: 14,
          minWidth: 20,
          minHeight: 5,
          marginTop: 10,
          marginBottom: 10,
        }}
        title="RESEND OTP"
      />
    </Form.Group>
  );
}

export default OtpInputUtil;
