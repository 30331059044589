import React, { useContext } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Login from '../pages/Login/Login';
import HomePage from '../pages/HomePage/HomePage';
import NavbarUtil from '../reusables/nav/Navbar';
import SignUp from '../pages/SignUp/SignUpMethod/SignUp';
import UploadPrescription from '../pages/Prescription/UploadPrescription/UploadPrescription';
import PlaceOrder from '../pages/Prescription/PlaceOrder/PlaceOrder';
import Email from '../pages/SignUp/Email/Email';
import Products from '../pages/Products/Products';
import { AuthContext } from '../reusables/context/AuthContext';

function Routes(props) {
  const { isLoader } = useContext(AuthContext);
  const location = useLocation();
  return (
    <>
      <LoadingOverlay active={isLoader} spinner>
        {/* {location.pathname === '/email' && window.innerWidth > 590 ? null : (
         
        )} */}
        <NavbarUtil />
        <div
          style={{
            position: 'absolute',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '45%',
            marginTop: 200
          }}
        ></div>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/signup' component={SignUp} />
          <Route path='/email' component={Email} />
          <Route path='/upload-prescription' component={UploadPrescription} />
          <Route path='/place-order' component={PlaceOrder} />
          <Route path='/products' component={Products} />
          <Route path='/' exact component={HomePage} />
        </Switch>
      </LoadingOverlay>
    </>
  );
}

export default Routes;
