import React from 'react';
import { Dropdown, Form, Row, Col } from 'react-bootstrap';
import Input from '../input/Input';

import { countries } from './countryCodes.json';
import styles from './PhoneInput.module.css';
import './PhoneInput.css';

function PhoneInput({
  countryCode,
  setCountryCode,
  setPhone,
  phone,
  mobNumber,
  changeText,
  style
}) {
  return (
    <Form.Group>
      <Form.Label>Phone</Form.Label>
      <span style={{ display: 'flex' }}>
        <Dropdown
          className={styles.dropdown}
          variant='outline-secondary'
          style={{ marginRight: 10, height: 34 }}
        >
          <Dropdown.Toggle variant='none' className={styles.code}>
            <span className={styles.codeName}>{countryCode}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu className={styles.drop}>
            {countries.map((country) => (
              <Dropdown.Item
                as='button'
                onClick={(e) => {
                  e.preventDefault();
                  setCountryCode(country.code);
                }}
              >
                <Row>
                  <Col md={6}>{country.name}</Col>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    {country.code}
                  </Col>
                </Row>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Input
          type='tel'
          placeholder='Enter your phone'
          value={mobNumber}
          onChange={changeText}
          style={style}
        />
      </span>
    </Form.Group>
  );
}

export default PhoneInput;
