import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import GradientButton from '../../../reusables/GradientButton/GradientButton';
import Input from '../../../reusables/input/Input';
import OtpInputUtil from '../../../reusables/OtpInput/OtpInputUtil';
import PhoneInput from '../../../reusables/PhoneInput/PhoneInput';

import styles from './PhoneSignUp.module.css';

function PhoneSignUp({ handleBack }) {
  const [countryCode, setCountryCode] = useState('+91');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');

  return (
    <Form className={styles.form}>
      <Form.Group controlId='formBasicEmail'>
        <Form.Label>Name</Form.Label>
        <InputGroup>
          <Input type='name' placeholder='Enter your name' />
        </InputGroup>
      </Form.Group>
      <PhoneInput
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        phone={phone}
        setPhone={setPhone}
        style={window.innerWidth < 589 ? { width: 45 } : null}
      />
      <OtpInputUtil otp={otp} setOtp={setOtp} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <GradientButton
          variant='Outline'
          title='Cancel'
          color='red'
          onClick={handleBack}
          style={{ margin: 10, width: '45%' }}
        />
        <GradientButton title='Continue' style={{ margin: 10, width: '45%' }} />
      </div>
    </Form>
  );
}

export default PhoneSignUp;
