import React from "react";
import { VscChromeClose } from "react-icons/vsc";

import styles from "./AfterUpload.module.css";

function AfterUpload({ image, handleRemove }) {
  return (
    <div>
      <img
        src={image}
        alt="Uploaded prescription"
        className={styles.uploadedImage}
      />
      <div className={styles.removeOuterDiv}>
        <span className={styles.remove} onClick={handleRemove}>
          <VscChromeClose style={{ width: 20, height: 20 }} />
        </span>
      </div>
    </div>
  );
}

export default AfterUpload;
