import React, { useState, useContext } from 'react';
import { AuthContext } from '../../../reusables/context/AuthContext';
import styles from './SignUp.module.css';
import Template from '../../../reusables/LoginSignupTemplate/Template';
import mail from '../../../../assets/images/mail-signup.png';
import phone from '../../../../assets/images/phone-signup.png';
import google from '../../../../assets/images/G-signup.png';
import EmailSignUp from '../EmailSignUp/EmailSignUp';
import PhoneSignUp from '../PhoneSignUp/PhoneSignUp';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
function SignUp(props) {
  const [signInMethod, setSignInMethod] = useState(null);
  const { isLoader } = useContext(AuthContext);
  const history = useHistory();

  const handleClick = (type) => {
    if (type === 'Google') {
      return;
    } else {
      setSignInMethod(type);
    }
  };

  const selectMethod = (
    <Container>
      <Row className={styles.container}>
        <Col className={styles.item} onClick={() => handleClick('Email')}>
          <img src={mail} alt='Mail' />
          <p>Email Address</p>
        </Col>
        <Col className={styles.item} onClick={() => handleClick('Phone')}>
          <img src={phone} alt='Phone' />
          <p>Phone Number</p>
        </Col>
        <Col className={styles.item} onClick={() => handleClick('Google')}>
          <img src={google} alt='Google' />
          <p>Google</p>
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      <Template
        title={signInMethod === 'Email' ? '' : 'Welcome'}
        subTitle={signInMethod === 'Email' ? '' : 'Sign up with'}
        content={
          signInMethod === null ? (
            selectMethod
          ) : signInMethod === 'Email' ? (
            history.push('/email')
          ) : (
            <PhoneSignUp handleBack={() => setSignInMethod(null)} />
          )
        }
        innerDivStyle={
          signInMethod !== null
            ? window.innerWidth < 569
              ? { width: '100%', alignSelf: 'center', marginLeft: '0%' }
              : { width: 360 }
            : null
        }
      />
    </>
  );
}

export default SignUp;
