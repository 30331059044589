import React from "react";
import AboutUs from "./AboutUs/AboutUs";
import Contact from "./Contact/Contact";
import { Element } from "react-scroll";

import Home from "./Home/Home";
import Products from "./Products/Products";

function HomePage(props) {
  return (
    <div>
      <Element name="Home">
        <Home />
      </Element>
      <Element name="About">
        <AboutUs />
      </Element>
      <Element name="Products">
        <Products />
      </Element>
      <Element name="Contact">
        <Contact />
      </Element>
    </div>
  );
}

export default HomePage;
