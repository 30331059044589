import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import cx from 'classnames';
import { Link, scroller } from 'react-scroll';

import { Link as RLink, useHistory, useLocation } from 'react-router-dom';

import './nav.css';
import styles from './navbar.module.css';
import logo from '../../../assets/images/logo.png';
import FirstRow from './FirstRow';
import Search from './Search';
import GradientButton from '../GradientButton/GradientButton';
import MobileNavBar from './MobileNavBar';

const linkStyle = {
  marginTop: 5
};

const Active = ({ title }) => (
  <div
    style={{ display: 'block', alignItems: 'center', justifyContent: 'center' }}
  >
    <span className={cx(styles.navlink, styles.active)}>{title}</span>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <span className={styles.dot}></span>
    </div>
  </div>
);

function NavLinks({
  inHome,
  setActive,
  active,
  handleClick,
  style = {},
  closeClick
}) {
  return (
    <>
      {inHome ? (
        <Link
          to='Home'
          spy={true}
          smooth={true}
          offset={-250}
          duration={500}
          id='home'
          onClick={() => {
            closeClick('home');
          }}
          onSetActive={() => setActive('home')}
          style={{ ...linkStyle, ...style }}
        >
          {active == 'home' ? (
            <Active title='Home' />
          ) : (
            <span className={styles.navlink}>Home</span>
          )}
        </Link>
      ) : (
        <RLink
          to='/'
          style={{ ...linkStyle, ...style }}
          className={cx(styles.navlink, styles.homelink)}
        >
          Home
        </RLink>
      )}
      {inHome ? (
        <Link
          to='About'
          spy={true}
          smooth={true}
          offset={-170}
          duration={500}
          id='about'
          style={{ ...linkStyle, ...style }}
          onClick={() => {
            closeClick('about');
          }}
          onSetActive={() => setActive('about')}
        >
          {active === 'about' ? (
            <Active title='About Us' />
          ) : (
            <span className={styles.navlink}>About Us</span>
          )}
        </Link>
      ) : (
        <RLink
          onClick={() => handleClick('about')}
          to='/#About'
          style={{ ...linkStyle, ...style }}
          className={cx(styles.navlink, styles.homelink)}
        >
          About Us
        </RLink>
      )}
      {inHome ? (
        <Link
          to='Products'
          spy={true}
          smooth={true}
          offset={-170}
          duration={500}
          id='products'
          style={{ ...linkStyle, ...style }}
          onClick={() => {
            closeClick('products');
          }}
          onSetActive={() => setActive('products')}
        >
          {active === 'products' ? (
            <Active title=' Products' />
          ) : (
            <span className={styles.navlink}>Products</span>
          )}
        </Link>
      ) : (
        <RLink
          onClick={() => handleClick('products')}
          to='/#Products'
          style={{ ...linkStyle, ...style }}
          className={cx(styles.navlink, styles.homelink)}
        >
          Products
        </RLink>
      )}
      {inHome ? (
        <Link
          to='Contact'
          spy={true}
          smooth={true}
          offset={-220}
          duration={500}
          id='contact'
          style={{ ...linkStyle, ...style }}
          onClick={() => {
            closeClick('contact');
          }}
          onSetActive={() => setActive('contact')}
        >
          {active === 'contact' ? (
            <Active title='Contact' />
          ) : (
            <span className={styles.navlink}>Contact</span>
          )}
        </Link>
      ) : (
        <RLink
          onClick={() => handleClick('contact')}
          to='/#Contact'
          style={{ ...linkStyle, ...style }}
          className={cx(styles.navlink, styles.homelink)}
        >
          Contact
        </RLink>
      )}
    </>
  );
}

export default NavLinks;
