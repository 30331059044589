import React from "react";
import Header from "../../../reusables/Header/Header";

import styles from "./About.module.css";

function AboutUs(props) {
  return (
    <div style={{ margin: 50 }}>
      <Header title="About Us - Medicine for Everyone Mission" />
      <p className={styles.about}>
        Medicine for Everyone's mission is to get you affordable medicines at
        your doorsteps at the lowest possible cost. We offer a wide range of
        drugs and medicines to our customers in India and Abroad. <br /> We
        distribute many Over-the-Counter (OTC) and other life-saving drugs to
        our patients. We attribute our growth to our repeat customers who trust
        us for their medical needs. We also sell to large, medium, and small
        enterprises. <br /> <br /> We are a leading supplier of a wide range of
        medicines related to Oncology ( Anti-cancer), Orthopedic, Neurology,
        Nephrology, Hematology (Blood & Plasma), Dermatology(Skin infections),
        Fungal Infection Drugs, and Medicines. <br />
        <br /> Also, we offer medicines for Cardio, Critical Care (ICU),
        Diabetic, Asthma Care, and Surgical Sutures. Our portfolio includes
        Patented, Off-patented (Generics), and others. <br />
        <br /> We at Medicine for Everyone have a strict code of ethics and
        comply with all Governing laws. We supply quality products at the best
        price. We strive personal attention to our patients and their families.
        <br />
        <br />
        Why Choose Medicine for Everyone?
        <ul>
          <li>Timely Shipping.</li>
          <li>Secure transactions.</li>
          <li>International Shipping.</li>
          <li>Personal attention.</li>
        </ul>
      </p>
    </div>
  );
}

export default AboutUs;
