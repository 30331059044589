import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControl, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import search from '../../../assets/icons/search.svg';
import { MedicineContext } from '../context/MedicineContext';
import RenderMedicine from '../Medicine/Medicine';
import AskPriceModal from '../AskPriceModal/AskPriceModal';

import styles from './SearchBar.module.css';

function SearchBar({ style, flex = 3, onFocus = null, onBlur = null }) {
  const [searchText, setSearchText] = useState('');

  const [searchedMedicines, setSearchedMedicines] = useState([]);
  const [showAskPrice, setShowAskPrice] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { medicines } = useContext(MedicineContext);
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setSearchText('');
  }, [location.pathname]);

  const handleTextChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
    setSearchedMedicines(
      medicines.filter((med) =>
        med.productName.toLowerCase().includes(text.toLowerCase())
      )
    );
  };

  const handleAskPrice = (medicine) => {
    setSelectedProduct(medicine);
    setShowAskPrice(true);
    setShow(true);
  };

  const closeModal = () => {
    setShowAskPrice(false);
    setShow(false);
  };
  return (
    <>
      <AskPriceModal
        isOpen={showAskPrice}
        medicine={selectedProduct}
        closeModal={() => closeModal()}
      />
      <div
        style={{
          border: '1px solid #1bc2ad',
          display: 'flex',
          height: '39px',
          borderRadius: 5,
          ...style
        }}
      >
        <img
          src={search}
          alt='search'
          width={20}
          height={20}
          style={{ margin: '8px' }}
        />
        <FormControl
          style={{
            borderWidth: 0,
            borderTopRightRadius: 13,
            borderBottomRightRadius: 13,
            height: 36
          }}
          className='shadow-none'
          placeholder={
            isMobile
              ? 'Search Products'
              : 'Search products by name, category and brand'
          }
          onChange={handleTextChange}
          value={searchText}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      {show ? null : (
        <>
          {searchText.length > 0 && (
            <>
              <Row className={styles.row}>
                {searchedMedicines.map((medicine) => (
                  <>
                    <RenderMedicine
                      medicine={medicine}
                      flex={flex}
                      handleAskPrice={() => handleAskPrice(medicine)}
                    />
                  </>
                ))}
                {searchedMedicines.length === 0 && (
                  <p className={styles.emptyText}>
                    We are sorry! We couldn't find anything for "{searchText}"
                  </p>
                )}
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
}

export default SearchBar;
