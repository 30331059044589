import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import GradientButton from "../../../reusables/GradientButton/GradientButton";

import styles from "./Home.module.css";

function Home(props) {
  const history = useHistory();


  return (
    <div className={styles.mainDiv}>
      <div style={{ textAlign: "center" }}>
        <p className={styles.text}>
          We are here to take care of <br /> your health!
        </p>
        {/* <p className={styles.subText}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p> */}
        <GradientButton
          style={{ minHeight: 50, minWidth: 170, fontSize: 22 }}
          onClick={() => history.push("/products")}
        >
          Shop Now <FaChevronRight size={18} style={{ marginBottom: 3 }} />
        </GradientButton>
      </div>
    </div>
  );
}

export default Home;
