import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/logo.png';
import GradientButton from '../GradientButton/GradientButton';
import Drawer from '@material-ui/core/Drawer';
import styles from './MobileSideBar.module.css';
import mailImg from '../../../assets/icons/mail.svg';
import whatsapp from '../../../assets/icons/whatsapp.svg';
import telegram from '../../../assets/images/telegram.png';
import viber from '../../../assets/images/viber.png';
import { mail, phone } from '../Constants';
import NavLinks from './NavLinks';
import { useLocation, useHistory } from 'react-router-dom';

function MobileSideBar({ isOpen, closeDrawer }) {
  const [inHome, setInHome] = useState(null);
  const [active, setActive] = useState(null);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.pathname === '/') {
      setInHome(true);
    } else {
      setInHome(false);
    }
  }, [location.pathname]);

  return (
    <Drawer anchor='left' open={isOpen} onClose={closeDrawer}>
      <img src={logo} alt='LOGO' className={styles.logo} />
      {localStorage.getItem('token') == null ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GradientButton
              style={{ margin: 5 }}
              variant='Outline'
              onClick={() => {
                history.push('/signup');
              }}
            >
              Sign Up
            </GradientButton>
            <GradientButton
              onClick={() => {
                history.push('/login');
              }}
              style={{ margin: 5 }}
            >
              Log In
            </GradientButton>
          </div>
        </>
      ) : null}
      <NavLinks
        inHome={inHome}
        setActive={setActive}
        closeClick={closeDrawer}
        handleClick={closeDrawer}
        active={closeDrawer}
        style={{ padding: 7.5, marginLeft: inHome ? 10 : 16 }}
      />
      <div className={styles.contactDiv}>
        <div className={styles.contact}>
          <img src={mailImg} alt='mail' className={styles.img} />
          <p> {mail}</p>
        </div>
        <div className={styles.contact}>
          <img src={whatsapp} alt='WhatsApp' className={styles.img} />
          <p>{phone}</p>
        </div>
        <div className={styles.contact}>
          <img src={telegram} alt='Telegram' className={styles.img} />
          <p>{phone}</p>
        </div>
        <div className={styles.contact}>
          <img src={viber} alt='Viber' className={styles.img} />
          <p>{phone}</p>
        </div>
      </div>
    </Drawer>
  );
}

export default MobileSideBar;
