import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-modal';
import GradientButton from '../GradientButton/GradientButton';
import Input from '../input/Input';
import styles from './BulkOrder.module.css';
import { VscChromeClose } from 'react-icons/vsc';

function BulkOrderModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <p className={styles.header}>Place Bulk Order</p>
      <p>
        To place a bulk order for an item, fill in this form and we can get in
        touch with you through email.
      </p>
      <Form className={styles.form}>
        <Form.Group controlId='formBasicEmail'>
          <Form.Label>Name</Form.Label>
          <InputGroup className='mb-3'>
            <Input type='name' placeholder='Enter your name' />
          </InputGroup>
        </Form.Group>

        <Form.Group controlId='formBasicPassword'>
          <Form.Label>Email</Form.Label>
          <InputGroup>
            <Input type='email' placeholder='Enter your Email' />
          </InputGroup>
        </Form.Group>

        <div
          style={{
            textAlign: 'center',
            flexDirection: 'row',
            display: 'flex',
            alignContent: 'space-between',
            justifyContent: 'space-between'
          }}
        >
          <GradientButton
            variant='Outline'
            color='red'
            title='Cancel'
            style={
              window.innerWidth > 580
                ? { margin: 10, minWidth: 150 }
                : { margin: 5, minWidth: 80 }
            }
            onClick={onClose}
          />
          <GradientButton
            handleClick={null}
            title='Confirm'
            style={
              window.innerWidth > 580
                ? { margin: 10, minWidth: 150 }
                : { margin: 5, minWidth: 80 }
            }
          />
        </div>
      </Form>
      <div className={styles.close} onClick={onClose}>
        <VscChromeClose size={20} color='#707070' />
      </div>
    </Modal>
  );
}

export default BulkOrderModal;
