import React, { useState } from "react";
import { Form } from "react-bootstrap";

import styles from "./PhoneLogin.module.css";
import GradientButton from "../../../reusables/GradientButton/GradientButton";
import PhoneInput from "../../../reusables/PhoneInput/PhoneInput";
import OtpInputUtil from "../../../reusables/OtpInput/OtpInputUtil";

function PhoneLogin(props) {
  const [countryCode, setCountryCode] = useState("+91");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");

  return (
    <Form>
      <PhoneInput
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        phone={phone}
        setPhone={setPhone}
      />
      <OtpInputUtil otp={otp} setOtp={setOtp} />

      <div style={{ textAlign: "center" }}>
        <GradientButton handleClick={null} title="Continue" />
      </div>
    </Form>
  );
}

export default PhoneLogin;
