export const optionsContent = [
  {
    id: 0,
    text: "Order everything as per prescription",
    checked: false,
    description:
      "We automatically place and order for everything mentioned on the prescription exactly as they are and deliver them to you! It would take us a day or two to inform you the total payable amount before your order is confirmed.",
  },
  {
    id: 1,
    text: "Search and add medicines to the cart manually",
    checked: false,
    description: null,
  },

  {
    id: 2,
    text: "Place a bulk order",
    checked: false,
    description:
      "We will get in touch and place your order through email in 24 hours ",
  },
  {
    id: 3,
    text: "Call us for details",
    checked: false,
    description:
      "We will call you from 011-41183088 within 30 mins to confirm medicines (8 am - 8 pm)",
  },
];
