import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AiOutlineLogout } from 'react-icons/ai';
import cx from 'classnames';
import { Link, scroller } from 'react-scroll';

import { Link as RLink, useHistory, useLocation } from 'react-router-dom';

import './nav.css';
import styles from './navbar.module.css';
import logo from '../../../assets/images/logo.png';
import FirstRow from './FirstRow';
import Search from './Search';
import GradientButton from '../GradientButton/GradientButton';
import MobileNavBar from './MobileNavBar';
import NavLinks from './NavLinks';
const axios = require('axios');
function NavbarUtil(props) {
  const [active, setActive] = useState(null);
  const [sticky, setSticky] = useState(false);
  const [inHome, setInHome] = useState(null);

  const location = useLocation();

  useEffect(() => {
    let _sticky =
      location.pathname === '/' || location.pathname === '/products';
    if (location.hash !== '') {
      console.log(location.hash.substr(1));
      scroller.scrollTo(location.hash.substr(1), {
        offset: -170
      });
    }
    setInHome(location.pathname === '/');
    setSticky(_sticky);
    if (_sticky) window.addEventListener('scroll', handleScroll);
    else setActive(null);
    return () => {
      if (sticky) window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, sticky]);

  const handleScroll = () => {
    let height = window.scrollY;
    if (sticky)
      if (height === 0)
        document.getElementById('shadow').classList.remove('shadow');
      else document.getElementById('shadow').classList.add('shadow');
  };

  const history = useHistory();

  const handleClick = async (id) => {
    // const
  };
  return (
    <>
      <FirstRow />
      <div id='shadow' className={sticky ? styles.sticky : null}>
        {window.innerWidth > 580 ? (
          <Navbar
            expand='md'
            style={{ backgroundColor: '#fff', display: 'flex' }}
          >
            <Navbar.Brand>
              <RLink to='/'>
                <img src={logo} alt='Logo' className={styles.logo} />
              </RLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />

            <Navbar.Collapse>
              <Nav className={cx(styles.nav, 'mr-auto')}>
                <NavLinks
                  active={active}
                  setActive={setActive}
                  inHome={inHome}
                  closeClick={setActive}
                  handleClick={handleClick}
                />
                {localStorage.getItem('token') != null ? (
                  <>
                    <Nav.Link
                      onClick={() => {
                        localStorage.removeItem('token');
                        history.push('/login');
                      }}
                      className={styles.navlink}
                      style={{ margin: 5, marginTop: -5, marginRight: 30 }}
                    >
                      <AiOutlineLogout size={25} color='#1cc2ad' />
                    </Nav.Link>
                  </>
                ) : null}
                {localStorage.getItem('token') == null ? (
                  <>
                    <RLink to='/signup' className={styles.signUp}>
                      <GradientButton style={{ margin: 5 }} variant='Outline'>
                        Sign Up
                      </GradientButton>
                    </RLink>
                    <RLink to='/login' className={styles.link}>
                      <GradientButton style={{ margin: 5 }}>
                        Log In
                      </GradientButton>
                    </RLink>
                  </>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        ) : (
          <MobileNavBar />
        )}
        <Search />
      </div>
    </>
  );
}

export default NavbarUtil;
