import React from "react";

import styles from "./Header.module.css";

function Header({ title }) {
  return (
    <>
      <h1
        className={styles.header}
        style={{ textAlign: "center", color: "#1bc2ad" }}
      >
        {title}
      </h1>
      <hr
        style={{
          alignSelf: "center",
          width: 35,
          borderColor: "#272727",
          marginTop: -5,
        }}
        className={styles.line}
      />
    </>
  );
}

export default Header;
