import React from "react";
import { BrowserView, isBrowser, MobileView } from "react-device-detect";
import cx from "classnames";

import mailImg from "../../../assets/icons/mail.svg";
import translate from "../../../assets/icons/translate.svg";
import whatsapp from "../../../assets/icons/whatsapp.svg";
import telegram from "../../../assets/images/telegram.png";
import viber from "../../../assets/images/viber.png";

import styles from "./navbar.module.css";
import { phone, mail } from "../Constants";

function FirstRow(props) {
  return (
    <div className={styles.upper}>
      <div>
        <img
          src={mailImg}
          alt="Mail"
          className={cx(styles.img, styles.mailImg)}
        />
        <a
          href={`https://mail.google.com/mail/?view=cm&fs=1&to=${mail}`}
          target="_blank"
          rel="noreferrer"
          className={styles.text}
        >
          {mail}
        </a>
      </div>

      <div className={styles.app}>
        <div className={styles.translateDiv}>
          <img
            src={translate}
            alt="Translate"
            className={styles.img}
            style={{ marginTop: 15 }}
          />
          <div id="google_translate_element"></div>
        </div>
        <span className={styles.text} style={{ marginRight: 3 }}>
          Contact us!{" "}
        </span>
        {window.innerWidth > 580 && (
          <>
            <img
              src={whatsapp}
              alt="WhatsApp"
              className={styles.img}
              title="WhatsApp"
            />{" "}
            |
            <img
              src={telegram}
              alt="Telegram"
              className={styles.img}
              style={{ margin: 5, marginTop: 2 }}
              title="Telegram"
            />{" "}
            |
            <img
              src={viber}
              alt="Viber"
              className={styles.img}
              style={{ margin: 5, marginTop: 2 }}
              title="Viber"
            />
          </>
        )}
        <span className={styles.text}>{phone}</span>
      </div>
    </div>
  );
}

export default FirstRow;
