import React, { useState, useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import cx from 'classnames';
import styles from './Email.module.css';
import logo from '../../../../assets/images/logo.png';
import logo1 from '../../../../assets/images/signUpLogo.png';
import Form from 'react-bootstrap/Form';
import InputEmail from '../../../reusables/InputEmail/InputEmail';
import GradientButton from '../../../reusables/GradientButton/GradientButton';
import { InputGroup, Spinner } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { AuthContext } from '../../../reusables/context/AuthContext';
import { validateRegister } from '../../../utils/validateCredentials';
import { useToasts } from 'react-toast-notifications';
import Toast from '../../../reusables/Toast/Toast.jsx';
import ReactTooltip from 'react-tooltip';
import PhoneInput from '../../../reusables/PhoneInput/PhoneInput';
import Input from '../../../reusables/input/Input';
import { InputAdornment } from '@material-ui/core';
const axios = require('axios');
function Email() {
  const history = useHistory();
  const { setLoader } = useContext(AuthContext);
  const { addToast } = useToasts();
  const [data, setData] = useState({
    fName: '',
    lName: '',
    email: '',
    password: '',
    phoneNo: ''
  });

  const [countryCode, setCountryCode] = useState('+91');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [cp, setCp] = useState('');
  const { isValid, error } = validateRegister(data, cp);
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [next, setNext] = useState(false);
  const inputRef = React.useRef(null);
  const send = async (e) => {
    e.preventDefault();
    if (isValid) {
      setLoader(true);
      e.preventDefault();
      console.log(data);
      // const { responseData, error } = await api_call({
      //   apiUrl: "/users/signIn",
      //   method: "post",
      //   body:data,
      // });
      // if (!error) {
      //   console.log(responseData);
      // }
      axios
        .post('https://www.medicineforeveryone.com/api/users/signUp', data)
        .then(function (response) {
          console.log(response);
          localStorage.setItem('token', response.data.token);
          setLoader(false);
          setTitle('Success');
          setMessage('Account registered successfully');
          setModal(true);
          history.push('/products');
        })
        .catch(function (e) {
          console.log(e.response.data.error);
          setTitle('Error');
          setMessage(e.response.data.error);
          setModal(true);
          setLoader(false);
        });
    } else {
      e.preventDefault();
      setTitle('Error');
      setMessage(error);
      setModal(true);
    }
  };
  return (
    <>
      {window.innerWidth > 590 ? (
        <div className={styles.outerDiv}>
          <Toast
            isOpen={modal}
            closeModal={() => setModal(false)}
            title={title}
            message={message}
          />

          <div
            className={styles.innerDiv}
            style={{
              margin: 20,
              width: '50%'
              // minWidth: window.innerWidth * 0.25,
            }}
          >
            <div className='firstBox'>
              <div style={{ alignSelf: 'center', textAlign: 'center' }}>
                <p className={styles.title}>
                  Welcome {'  '} <span style={{ color: '#1bc2ad' }}>!</span>
                </p>

                <p className={styles.subTitle}>
                  Sign up to create a new account
                </p>
              </div>
              <Form className={styles.form}>
                <ReactTooltip id='title required' effect='solid' place='bottom'>
                  "Title required"
                </ReactTooltip>

                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>First Name</Form.Label>
                  <InputGroup>
                    <InputEmail
                      type='name'
                      style={{ width: '197px' }}
                      placeholder='Enter your name'
                      value={data.fName}
                      onChange={(e) =>
                        setData({ ...data, fName: e.target.value })
                      }
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  controlId='formBasicEmail'
                  style={{ marginLeft: '20px' }}
                >
                  <Form.Label>Last Name</Form.Label>
                  <InputGroup>
                    <InputEmail
                      style={{ width: '197px' }}
                      type='name'
                      placeholder='Enter your name'
                      value={data.lName}
                      onChange={(e) =>
                        setData({ ...data, lName: e.target.value })
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
              <Form className={styles.form}>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Email address</Form.Label>
                  <InputGroup>
                    <InputEmail
                      type='email'
                      style={{ width: '415px' }}
                      placeholder='Enter your email'
                      value={data.email}
                      onChange={(e) =>
                        setData({ ...data, email: e.target.value })
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form>

              <Form className={styles.form}>
                <Form.Group controlId='formBasicPassword'>
                  <Form.Label>Password</Form.Label>
                  <InputGroup>
                    <InputEmail
                      type={show ? 'none' : 'password'}
                      data-tip
                      data-for='title required'
                      placeholder='Password'
                      value={data.password}
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                      style={{
                        borderRight: 'none',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                      }}
                    />

                    <InputGroup.Append>
                      <button
                        className={styles.showPass}
                        onClick={(e) => {
                          e.preventDefault();
                          setShow(!show);
                        }}
                      >
                        {show ? 'Hide' : 'Show'}
                      </button>
                    </InputGroup.Append>
                  </InputGroup>
                  {/* <p
            style={{
              fontSize: 12,
              color: 'grey',
              marginTop: 5,
              marginLeft: 5
            }}
          >
            Password should contain atleast one Uppercase,one LowerCase and
            one digit
          </p> */}
                </Form.Group>

                <Form.Group
                  controlId='formBasicPassword'
                  style={{ marginLeft: '20px' }}
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <InputEmail
                      type={show1 ? 'none' : 'password'}
                      placeholder='Confirm password'
                      value={data.cp}
                      onChange={(e) => setCp(e.target.value)}
                      style={{
                        borderRight: 'none',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        width: '140px'
                      }}
                    />
                    <InputGroup.Append>
                      <button
                        className={styles.showPass}
                        onClick={(e) => {
                          e.preventDefault();
                          setShow1(!show1);
                        }}
                      >
                        {show1 ? 'Hide' : 'Show'}
                      </button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>
              <Form className={styles.form}>
                <Form.Group controlId='formBasicPassword'>
                  <InputGroup>
                    <PhoneInput
                      countryCode={countryCode}
                      style={{ width: 340 }}
                      setCountryCode={setCountryCode}
                      MobNumber={data.phoneNo}
                      changeText={(e) =>
                        setData({
                          ...data,
                          phoneNo: countryCode + e.target.value
                        })
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <GradientButton
                  title='Cancel'
                  variant='Outline'
                  color='red'
                  style={{ marginRight: 10, width: '35%' }}
                  onClick={() => history.push('/signup')}
                />
                <GradientButton
                  title='Sign Up'
                  style={{ marginLeft: 10, width: '35%' }}
                  onClick={send}
                />
              </span>

              <div className={styles.footer}>
                <p style={{ marginTop: 15 }}>Already have an account? </p>
                <Link to='/login'>
                  <button className={styles.footerButton}>LOGIN</button>
                </Link>
              </div>
            </div>
            <div className='secondBox'>
              <div>
                <span>
                  <Link to='/'>
                    <span style={{ float: 'right', marginRight: '-100px' }}>
                      <AiOutlineClose size={20} color='black' />
                    </span>
                  </Link>
                </span>
              </div>
              <div>
                <img src={logo} alt='Logo' className={styles.logo} />
              </div>
              <div>
                <img src={logo1} alt='Logo1' className={styles.logo1} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        /**
         * Mobile screen for sign up
         */

        <div className={styles.outerDiv}>
          <Toast
            isOpen={modal}
            closeModal={() => setModal(false)}
            title={title}
            message={message}
          />

          <div
            className={styles.innerDiv}
            // style={{
            //   margin: 20,
            //   width: '50%'
            //   // minWidth: window.innerWidth * 0.25,
            // }}
          >
            <span>
              <Link to='/'>
                <span style={{ float: 'right', marginRight: '10px' }}>
                  <AiOutlineClose size={20} color='black' />
                </span>
              </Link>
            </span>
            <div style={{ alignSelf: 'center', textAlign: 'center' }}>
              <p className={styles.title}>
                Welcome {'  '} <span style={{ color: '#1bc2ad' }}>!</span>
              </p>

              <p className={styles.subTitle}>Sign up to create a new account</p>
            </div>
            <Form className={styles.form}>
              <ReactTooltip id='title required' effect='solid' place='bottom'>
                "Title required"
              </ReactTooltip>
              {next === false ? (
                <>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Label>First Name</Form.Label>
                    <InputGroup>
                      <Input
                        type='name'
                        placeholder='Enter your name'
                        value={data.fName}
                        onChange={(e) =>
                          setData({ ...data, fName: e.target.value })
                        }
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId='formBasicEmail'>
                    <Form.Label>Last Name</Form.Label>
                    <InputGroup>
                      <Input
                        type='name'
                        placeholder='Enter your name'
                        value={data.lName}
                        onChange={(e) =>
                          setData({ ...data, lName: e.target.value })
                        }
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId='formBasicEmail'>
                    <Form.Label>Email address</Form.Label>
                    <InputGroup>
                      <Input
                        type='email'
                        placeholder='Enter your email'
                        value={data.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId='formBasicPassword'>
                    <InputGroup>
                      <PhoneInput
                        countryCode={countryCode}
                        style={{ marginTop: -3, width: '5%' }}
                        setCountryCode={setCountryCode}
                        MobNumber={data.phoneNo}
                        changeText={(e) =>
                          setData({
                            ...data,
                            phoneNo: countryCode + e.target.value
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group
                    controlId='formBasicPassword'
                    style={{ marginTop: '10%' }}
                  >
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Input
                        type={show ? 'none' : 'password'}
                        data-tip
                        data-for='title required'
                        placeholder='Password'
                        value={data.password}
                        onChange={(e) =>
                          setData({ ...data, password: e.target.value })
                        }
                        style={{
                          borderRight: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          width: '50px'
                        }}
                      />

                      <InputGroup.Append>
                        <button
                          className={styles.showPass}
                          onClick={(e) => {
                            e.preventDefault();
                            setShow(!show);
                          }}
                        >
                          {show ? 'Hide' : 'Show'}
                        </button>
                      </InputGroup.Append>
                    </InputGroup>
                    {/* <p
            style={{
              fontSize: 12,
              color: 'grey',
              marginTop: 5,
              marginLeft: 5
            }}
          >
            Password should contain atleast one Uppercase,one LowerCase and
            one digit
          </p> */}
                  </Form.Group>

                  <Form.Group controlId='formBasicPassword'>
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <Input
                        type={show1 ? 'none' : 'password'}
                        placeholder='Confirm password'
                        value={data.cp}
                        onChange={(e) => setCp(e.target.value)}
                        style={{
                          borderRight: 'none',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          width: '50px'
                        }}
                      />
                      <InputGroup.Append>
                        <button
                          className={styles.showPass}
                          onClick={(e) => {
                            e.preventDefault();
                            setShow1(!show1);
                          }}
                        >
                          {show1 ? 'Hide' : 'Show'}
                        </button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </>
              )}
            </Form>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {next ? (
                <GradientButton
                  title='Back'
                  variant='Outline'
                  color='red'
                  style={{ marginRight: 10, width: '35%' }}
                  onClick={() => setNext(false)}
                />
              ) : (
                <GradientButton
                  title='Cancel'
                  variant='Outline'
                  color='red'
                  style={{ marginRight: 10, width: '35%' }}
                  onClick={() => history.push('/signup')}
                />
              )}

              {next ? (
                <GradientButton
                  title={'Sign Up'}
                  style={{ marginLeft: 10, width: '35%' }}
                  onClick={send}
                />
              ) : (
                <GradientButton
                  title={'Next'}
                  style={{ marginLeft: 10, width: '35%' }}
                  onClick={() => setNext(true)}
                />
              )}
            </span>

            <div className={styles.footer}>
              <p style={{ marginTop: 15 }}>Already have an account? </p>
              <Link to='/login'>
                <button className={styles.footerButton}>LOGIN</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Email;
