import React from 'react';
import { Col } from 'react-bootstrap';
import styles from './Medicine.module.css';

const RenderMedicine = ({ medicine, flex = 3, handleAskPrice }) => (
  <Col lg={flex} className={styles.productDiv}>
    <div className={styles.product}>
      <img
        src={`https://www.plootus.com/medImages/${medicine.path}.jpg`}
        alt={medicine.productName + 'Image'}
        className={styles.productImage}
      />
      <div className={styles.enquiryDiv}>
        <button className={styles.enquiry} onClick={handleAskPrice}>
          Ask Price
        </button>
      </div>
    </div>
    <p className={styles.productName}>{medicine.productName}</p>
    {/* {medicine.productName.match(/[0-9]+(mg|MG)/g) ? (
      <>
        <p className={styles.productName}>
          {medicine.productName.substr(
            0,
            medicine.productName.lastIndexOf(" ")
          )}
        </p>
        <p className={styles.mg}>
          {medicine.productName.substr(medicine.productName.lastIndexOf(" "))}
        </p>
      </>
    ) : (
      
    )} */}
  </Col>
);

export default RenderMedicine;
