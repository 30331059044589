import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineShoppingCart } from 'react-icons/ai';

import Toast from '../../reusables/Toast/Toast';

import styles from './navbar.module.css';
import logo from '../../../assets/images/logo.png';
import GradientButton from '../GradientButton/GradientButton';
import MobileSideBar from './MobileSideBar';
import { useHistory } from 'react-router-dom';

function MobileNavBar(props) {
  const [showSideBar, setShowSideBar] = useState(false);
  const history = useHistory();
  const [title, setTitle] = useState('Error');
  const [message, setMessage] = useState('Please Sign in to upload');
  const [modal, setModal] = useState(false);
  return (
    <>
      <Toast
        isOpen={modal}
        closeModal={() => setModal(false)}
        title={title}
        message={message}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px',
          justifyContent: 'space-between'
        }}
      >
        <span>
          <AiOutlineMenu
            size={25}
            color='#1bc2ad'
            onClick={() => setShowSideBar(true)}
          />
          <img src={logo} alt='Logo' className={styles.logo} />
        </span>
        <span>
          {/* <AiOutlineShoppingCart
            size={25}
            style={{ margin: 10 }}
            color="#6f6f6f"
          /> */}
          <GradientButton
            title='UPLOAD PRESCRIPTION'
            style={{
              minHeight: 35,
              minWidth: 100,
              fontWeight: 500,
              fontSize: 10
            }}
            onClick={() => {
              localStorage.getItem('token') == null
                ? setModal(true)
                : history.push('/upload-prescription');
            }}
          />
        </span>
      </div>
      <MobileSideBar
        isOpen={showSideBar}
        closeDrawer={() => setShowSideBar(false)}
      />
    </>
  );
}

export default MobileNavBar;
