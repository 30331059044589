import React, { useState } from "react";
import styles from "./UploadPrescription.module.css";
import cx from "classnames";

import Steps from "./Steps";
import AfterUpload from "./AfterUpload/AfterUpload";
import GradientButton from "../../../reusables/GradientButton/GradientButton";
import { useHistory } from "react-router-dom";
import validPres from "../../../../assets/images/validPres.png";

function UploadPrescription() {
  const [image, setImage] = useState(null);
  const history = useHistory();

  const handleImageUpload = (imageList) => {
    console.log(imageList);
    setImage(imageList[0].image);
  };

  return (
    <div className={styles.container}>
      <div style={{ flexDirection: "column" }}>
        <div className={styles.heading}>
          <h4 style={{ color: "#1bc2ad" }}> Upload Prescription</h4>
          <span style={{ color: "#6f6f6f" }}>
            Please attach a prescription to proceed
          </span>
        </div>
        <div className={styles.body}>
          <div className={styles.stepsBox}>
            {image === null ? (
              <Steps handleImageUpload={handleImageUpload} />
            ) : (
              <AfterUpload image={image} handleRemove={() => setImage(null)} />
            )}
          </div>

          <div className={styles.bottomView}>
            <p
             className={styles.bottomText}
            >
              What if I don't have a valid prescription?
            </p>
            <div className={styles.continueButton}>
              <GradientButton
                title="Continue"
                style={
                  image === null
                    ? {
                        backgroundColor: "#d8d8d8",
                        backgroundImage: "none",
                        width: 150,
                      }
                    : { width: 150 }
                }
                onClick={
                  image !== null
                    ? () =>
                        history.push({
                          pathname: "/place-order",
                          state: { image: image },
                        })
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.secondBox}>
        <p
          className={styles.secondBoxHeading}
        >
          What is a valid Prescription ?
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={validPres}
            alt="Valid Prescription"
           className={styles.prescriptionImage}
          />
        </div>
        <ul className={styles.pointsText}>
          <li>Don't crop out any part of the image</li>
          <li>Avoid blurred image</li>
          <li>Include details of doctor and patient + clinic visit date</li>
          <li>Medicines will be dispensed as per prescription</li>
          <li>Supported files types: jpeg, jpg, png, pdf</li>
        </ul>
      </div>
    </div>
  );
}

export default UploadPrescription;
