import React from "react";
import Header from "../../../reusables/Header/Header";
import cx from "classnames";

import styles from "./Contact.module.css";
import mailImg from "../../../../assets/icons/mail.svg";
import whatsapp from "../../../../assets/icons/whatsapp.svg";
import telegram from "../../../../assets/images/telegram.png";
import viber from "../../../../assets/images/viber.png";
import { Col, Container, Row } from "react-bootstrap";
import PageFooter from "../../../reusables/PageFooter";
import { mail, phone } from "../../../reusables/Constants";

function Contact(props) {
  return (
    <>
      <div style={{ paddingBottom: 40 }}>
        <Header title="Contact" />
        <Row className={cx(styles.mainDiv)}>
          <Col
            className={cx(
              styles.contentDiv,
              "justify-content-center",
              styles.border
            )}
          >
            <img src={mailImg} alt="Mail" className={styles.mailImage} />
            <p className={styles.text1}>Email Us at - </p>
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=metechnology2@gmail.com"
              className={styles.text2}
              target="_blank"
              rel="noreferrer"
            >
              {mail}
            </a>
          </Col>
          <Col className={cx(styles.contentDiv)}>
            <div className={styles.contactDiv}>
              <img
                src={whatsapp}
                alt="Whatsapp"
                className={styles.whatsappImage}
              />
              <img
                src={telegram}
                alt="Whatsapp"
                className={styles.whatsappImage}
              />
              <img
                src={viber}
                alt="Whatsapp"
                className={styles.whatsappImage}
              />
            </div>
            <p className={styles.text1}>Contact us!</p>
            <p className={styles.text2}>{phone}</p>
          </Col>
        </Row>
      </div>
      <PageFooter />
    </>
  );
}

export default Contact;
