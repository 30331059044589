import React, { useState } from 'react';
import cx from 'classnames';
import ImageUploading from 'react-images-uploading';
import uploadpres from '../../../../assets/icons/uploadpres.svg';
import savedpres from '../../../../assets/icons/savedpres.svg';
import Toast from '../../../reusables/Toast/Toast';
import styles from './UploadPrescription.module.css';

function Steps({ handleImageUpload }) {
  const [title, setTitle] = useState('Error');
  const [message, setMessage] = useState('Please Sign in to upload');
  const [modal, setModal] = useState(false);
  return (
    <div>
      <Toast
        isOpen={modal}
        closeModal={() => setModal(false)}
        title={title}
        message={message}
      />
      <p className={styles.stepHeadings}>
        3 easy steps to order your medicine!
      </p>
      <div className={styles.stepRow}>
        <div className={styles.stepColumn}>
          <div className={cx(styles.stepCircle, styles.extraCircle)}>
            <h1 className={styles.number1}>1</h1>
          </div>
          <p className={styles.number1Text}>Upload a valid prescription</p>
        </div>
        <div className={styles.stepColumn}>
          <div className={cx(styles.stepCircle, styles.extraCircle)}>
            <h1 className={styles.number2}>2</h1>
          </div>
          <p className={styles.number2Text}>Receive a confirmation call</p>
        </div>
        <div className={styles.stepColumn}>
          <div className={cx(styles.stepCircle, styles.extraCircle1)}>
            <h1 className={styles.number3}>3</h1>
          </div>
          <p className={styles.number3Text}>Deliver at your door step</p>
        </div>
      </div>
      <div className={styles.buttonsView}>
        <ImageUploading
          onChange={handleImageUpload}
          dataURLKey='image'
          maxNumber={1}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps
          }) => (
            <div className={styles.button} onClick={onImageUpload}>
              <div className={styles.btnImgView}>
                <img
                  src={uploadpres}
                  alt='upload'
                  className={styles.buttonImage}
                />
              </div>
              <div className={styles.verticalLine}></div>
              <p className={styles.btnText}>Upload Prescription</p>
            </div>
          )}
        </ImageUploading>
        <div className={styles.button}>
          <div className={styles.btnImgView}>
            <img src={savedpres} alt='saved' className={styles.buttonImage} />
          </div>
          <div className={styles.verticalLine}></div>
          <p className={styles.btnText}>Saved Prescription</p>
        </div>
      </div>
    </div>
  );
}

export default Steps;
