import React, { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import cx from 'classnames';
import { BrowserView, MobileView } from 'react-device-detect';
import search from '../../../assets/icons/search.svg';
import Toast from '../../reusables/Toast/Toast';
import styles from './Search.module.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Col, Container, Row } from 'react-bootstrap';
import GradientButton from '../GradientButton/GradientButton';
import { useHistory } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';

function Search(props) {
  const [clickedSearch, setClickedSearch] = useState(false);
  const history = useHistory();
  const [title, setTitle] = useState('Error');
  const [message, setMessage] = useState('Please Sign in to upload');
  const [modal, setModal] = useState(false);
  return (
    <div className={styles.mainDiv}>
      <Toast
        isOpen={modal}
        closeModal={() => setModal(false)}
        title={title}
        message={message}
      />
      <BrowserView>
        <div style={{ display: 'flex', flex: 1 }}>
          <div style={{ display: 'flex', flex: 6 }}>
            <div style={{ flex: 1, marginLeft: '13px' }}>
              <SearchBar
                style={{
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px'
                }}
              />
            </div>
          </div>
          <div style={{ marginLeft: 10, flex: 1 }}>
            <GradientButton
              title='UPLOAD PRESCRIPTION'
              style={{
                minHeight: 40,
                minWidth: 185,
                fontWeight: 500,
                fontSize: 14
              }}
              onClick={() => {
                localStorage.getItem('token') == null
                  ? setModal(true)
                  : history.push('/upload-prescription');
              }}
            />
          </div>
        </div>
      </BrowserView>
      <MobileView style={{ flex: 1, display: 'flex' }}>
        <div style={{ flex: 1.2 }}>
          <SearchBar
            style={{
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px'
            }}
            onFocus={() => setClickedSearch(true)}
            onBlur={() => setClickedSearch(false)}
          />
        </div>
      </MobileView>
    </div>
  );
}

export default Search;
