import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../../reusables/Header/Header";
import cat1 from "../../../../assets/images/medicine-category/antibiotics.png";
import cat2 from "../../../../assets/images/medicine-category/anticancer.png";
import cat3 from "../../../../assets/images/medicine-category/antiviral.png";
import cat4 from "../../../../assets/images/medicine-category/arthritis.png";
import cat5 from "../../../../assets/images/medicine-category/asthma.png";
import cat6 from "../../../../assets/images/medicine-category/hepatitis.png";
import cat7 from "../../../../assets/images/medicine-category/kidneydisease.png";
import cat8 from "../../../../assets/images/medicine-category/multivitamins.png";
import cat9 from "../../../../assets/images/medicine-category/skin.png";

import styles from "./Products.module.css";
import { useHistory } from "react-router-dom";

function Products(props) {
  const history = useHistory();

  return (
    <div>
      <Header title="Our Products" />
      <Container className={styles.products}>
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          {categories.map((category) => (
            <Col
              lg={3}
              sm={3}
              md={3}
              style={{
                backgroundImage: `url(${category.img})`,
              }}
              className={styles.category}
              onClick={() =>
                history.push(
                  `/products#${category.name
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`
                )
              }
            >
              <p className={styles.categoryName}>{category.name}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Products;

const categories = [
  {
    name: "ANTIBIOTICS",
    img: cat1,
    id: "category1",
  },
  {
    name: "ANTI-CANCER DRUGS",
    img: cat2,
    id: "categor2",
  },
  {
    name: "ANTIVIRAL DRUGS",
    img: cat3,
    id: "category3",
  },
  {
    name: "ARTHRITIS",
    img: cat4,
    id: "categor4",
  },
  {
    name: "ASTHMA DRUGS",
    img: cat5,
    id: "category5",
  },
  {
    name: "HEPATITIS",
    img: cat6,
    id: "category6",
  },
  {
    name: "KIDNEY DISEASE DRUGS",
    img: cat7,
    id: "category7",
  },
  {
    name: "MULTIVITAMINS",
    img: cat8,
    id: "category8",
  },
  {
    name: "SKIN DRUGS",
    img: cat9,
    id: "category9",
  },
];
