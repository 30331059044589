import React from 'react';
import Medicines from './MedicineContext';
import Auth from './AuthContext';

function Context({ children, ...props }) {
  return (
    <Auth>
      <Medicines>{children}</Medicines>
    </Auth>
  );
}

export default Context;
