import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { AuthContext } from '../context/AuthContext';
import GradientButton from '../GradientButton/GradientButton';
import PhoneInput from '../PhoneInput/PhoneInput';
import styles from './AskPrice.Module.css';
import Toast from '../Toast/Toast.jsx';
import { askprice } from '../../utils/validateCredentials';

import { VscChromeClose } from 'react-icons/vsc';

const axios = require('axios');

export default function AskPriceModal({
  isOpen,
  medicine,
  closeModal,
  ...props
}) {
  const [Mob, setMob] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const { setLoader } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  const { isValid, error } = askprice(Mob);
  const send = (phone, id) => {
    setLoader(true);
    console.log(phone);
    if (isValid) {
      axios
        .post('https://www.medicineforeveryone.com/api/products/askPrice', {
          productId: id,
          mobileNo: phone
        })
        .then(function (response) {
          console.log(response);
          setLoader(false);
          setTitle('Success');
          setMessage('Your request sent successfuly');
          setModal(true);
          setShow(true);
        })

        .catch(function (error) {
          setTitle('Error');
          setMessage('There is problem with request');
          setModal(true);
          console.log(error);
          setLoader(false);
          setShow(true);
        });
    } else {
      setTitle('Error');
      setMessage(error);
      setModal(true);
      setLoader(false);
      setShow(true);
    }
  };

  const close = () => {
    setModal(false);
    setShow(false);
  };
  return (
    <>
      <Toast
        isOpen={modal}
        closeModal={() => close()}
        title={title}
        message={message}
      />
      {show ? null : (
        <>
          <Modal
            isOpen={isOpen}
            className={styles.modal}
            onRequestClose={closeModal}
          >
            <div className={styles.container}>
              <div style={{ flex: 1 }}>
                <img
                  src={
                    medicine &&
                    `https://www.plootus.com/medImages/${medicine.path}.jpg`
                  }
                  alt={medicine && medicine.productName}
                  className={styles.image}
                />
                {/* <div> */}
              </div>
              <div className={styles.phone}>
                <PhoneInput
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  MobNumber={Mob}
                  changeText={(e) => setMob(e.target.value)}
                />
                <GradientButton
                  title='Ask Price?'
                  onClick={() => send(Mob, medicine._id)}
                />
              </div>
            </div>
            <p>{medicine && medicine.productName}</p>
            <p>{medicine && medicine.description}</p>
            {/* </div> */}
            <div className={styles.close} onClick={closeModal}>
              <VscChromeClose size={20} color='#707070' />
            </div>
          </Modal>
        </>
      )}
    </>
  );
}
