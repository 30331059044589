const PageFooter = () => (
  <div
    style={{
      backgroundColor: "#c7f7f4",
      padding: 5,
      textAlign: "center",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      fontSize: "15px",
    }}
  >
    Copyright
  </div>
);

export default PageFooter;
