import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { MedicineContext } from "../../reusables/context/MedicineContext";
import Header from "../../reusables/Header/Header";
import { productsData } from "./productsData";
import styles from "./Products.module.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { animateScroll, Element, Link } from "react-scroll";
import PageFooter from "../../reusables/PageFooter";
import { useLocation } from "react-router-dom";
import RenderMedicine from "../../reusables/Medicine/Medicine";
import AskPriceModal from "../../reusables/AskPriceModal/AskPriceModal";
import { isMobile } from "react-device-detect";
import cx from "classnames";

function Products(props) {
  const [viewMore, setViewMore] = useState({
    i1: false,
    i2: false,
    i3: false,
    i4: false,
    i5: false,
    i6: false,
    i7: false,
    i8: false,
    i9: false,
  });
  const [pageYOffset, setPageYOffset] = useState(0);
  const [showAskPrice, setShowAskPrice] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { medicines } = useContext(MedicineContext);
  const [active, setActive] = useState("Antibiotics");

  const location = useLocation();

  useEffect(() => {
    if (location.hash === "") {
      animateScroll.scrollToTop({
        duration: 0,
        delay: 0,
        smooth: false,
      });
    }
  }, [location.hash]);

  const handleViewMore = (id) => {
    if (viewMore[id]) {
      setViewMore((prev) => ({ ...prev, [id]: false }));
      animateScroll.scrollTo(pageYOffset, {
        duration: 100,
        delay: 0,
        smooth: true,
      });
    } else {
      setViewMore((prev) => ({ ...prev, [id]: true }));
      setPageYOffset(window.pageYOffset);
    }
  };

  const handleAskPrice = (medicine) => {
    setSelectedProduct(medicine);
    setShowAskPrice(true);
  };

  return (
    <div style={{ paddingBottom: "30px" }}>
      <AskPriceModal
        isOpen={showAskPrice}
        medicine={selectedProduct}
        closeModal={() => setShowAskPrice(false)}
      />
      <div className={styles.pageHeaderDiv}>
        <p className={styles.pageTitle}>Our Products</p>
      </div>
      <div className={styles.header}>
        <div className={styles.catNav}>
          {productsData.map((product) => (
            <Link
              to={product.name.toLowerCase().split(" ").join("-")}
              spy={true}
              smooth={true}
              offset={-220}
              duration={500}
              // id={product.name.toLowerCase().split(" ").join("-")}
              onSetActive={() =>
                setActive(product.name.toLowerCase().split(" ").join("-"))
              }
            >
              <p
                className={
                  active === product.name.toLowerCase().split(" ").join("-")
                    ? styles.active
                    : styles.catNavText
                }
              >
                {product.name}
              </p>
            </Link>
          ))}
        </div>
      </div>
      {medicines.length > 0 ? (
        <>
          {productsData.map((product) => (
            <Element id={product.name.toLowerCase().split(" ").join("-")}>
              <Header title={product.name} />
              {/* <Container> */}
              <div className={styles.productsContainer}>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {medicines
                    .filter(
                      (med) =>
                        med.category.toLowerCase().split(" ").join("-") ===
                        product.name.toLowerCase().split(" ").join("-")
                    )
                    .map((medicine, index) => (
                      <>
                        {((!isMobile && index < 8) ||
                          (isMobile && index < 9) ||
                          viewMore[product.id]) && (
                          <RenderMedicine
                            medicine={medicine}
                            flex={3}
                            handleAskPrice={() => handleAskPrice(medicine)}
                          />
                        )}
                      </>
                    ))}
                </Row>
                <div
                  className={styles.viewMore}
                  onClick={() => handleViewMore(product.id)}
                >
                  <p style={{ color: "#1bc2ad" }}>
                    View {}
                    {viewMore[product.id] ? (
                      <>
                        <span>Less </span>
                        <FaChevronUp
                          style={{ margin: "2px", marginBottom: 5 }}
                          size={13}
                          color="#1bc2ad"
                        />
                      </>
                    ) : (
                      <>
                        <span>More </span>
                        <FaChevronDown
                          style={{ margin: "2px", marginBottom: 5 }}
                          size={13}
                          color="#1bc2ad"
                        />
                      </>
                    )}
                  </p>
                </div>
                {/* </Container> */}
              </div>
            </Element>
          ))}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <Spinner animation="border" style={{ color: "#1bc2ad" }} />
        </div>
      )}
      <PageFooter />
      
    </div>
  );
}

export default Products;
