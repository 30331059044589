export let productsData = [
  {
    name: "Antibiotics",
    id: "i1",
  },
  {
    name: "Anti-Cancer Drugs",
    id: "i2",
  },
  {
    name: "Antiviral Drugs",
    id: "i3",
  },
  {
    name: "Arthritis",
    id: "i4",
  },
  {
    name: "Asthma Drugs",
    id: "i5",
  },
  {
    name: "Hepatitis",
    id: "i6",
  },
  {
    name: "Kidney Disease Drugs",
    id: "i7",
  },
  {
    name: "Multivitamins",
    id: "i8",
  },
  {
    name: "Skin Drugs",
    id: "i9",
  },
];
