import axios from 'axios';

const BASE_URL = 'https://www.medicineforeveryone.com/api/';

axios.defaults.baseURL = BASE_URL;

export const api_call = async ({
  apiUrl = '/',
  body = {},
  method = 'get',
  isTokenRequired = false
}) => {
  try {
    const config = {
      method,
      url: apiUrl,
      [method === 'get' ? 'params' : 'data']: body,
      timeout: 4000
    };
    console.log(config);

    if (isTokenRequired) {
      const token = await localStorage.getItem('token');
      axios.defaults.headers.Authorization = `${token}`;
    }

    const response = await axios(config);
    const responseData = response.data;
    console.log(response.status, responseData);

    return { responseData, error: false };
  } catch (error) {
    console.log(`Error in fetching ${apiUrl} api:->${error}`);

    if (error.response?.data) {
      console.log(
        `Error response ${JSON.stringify(error.response.data, null, 2)}`
      );

      return {
        responseData: error.response,
        error: true
      };
    }

    console.log('Unhandled Server Error');

    return {
      responseData: error.response,
      error: true
    };
  }
};
